import React from 'react';
import { Route, HashRouter } from 'react-router-dom';
import './App.css';
import store from './services/redux/store';
import { Provider } from 'react-redux';
import globalConst from 'services/globalConst';
import PageSpinner from 'components/Loader';

const Timer = React.lazy(() => import('timer/pages'));
const Wishlist = React.lazy(() => import('wishlist/pages'));
const DataFeed = React.lazy(() => import('datafeed'));
const MetaFields = React.lazy(() => import('metaFields'));
const AutoTagging = React.lazy(() => import('auto-tagging'));
const Search = React.lazy(() => import('search'));
const SocialLogin = React.lazy(() => import('socialLoginPlus'));
const SocialLoginHype = React.lazy(() => import('socialLoginPlusHype'));



function App() {
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>

    <HashRouter>
      <React.Suspense fallback={<PageSpinner />}>
        <Provider store={store}>
          <Route path="/timer/:id" component={Timer} />
          <Route path="/wishlist" component={Wishlist} />
          <Route path="/datafeed/:id" component={DataFeed} />
          <Route path="/metafields/:id" component={MetaFields} />
          <Route path="/autotagging/:id" component={AutoTagging} />
          <Route path="/search/:id" component={Search} />
          <Route path={`/${globalConst.appCode.socialLogin}/:id`} component={SocialLogin} />
          <Route path={`/${globalConst.appCode.socialLoginHype}/:id`} component={SocialLoginHype} />
        </Provider>
      </React.Suspense>
    </HashRouter>

  );
}

export default App;
