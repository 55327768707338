const shopify_endpoints: any = {
    products: 'Products',
    variants: 'Variants',
    collections: 'Collections',
    collects: 'Collects',
    smart_collections: 'Smart Collections',
    custom_collections: 'Custom Collections',
    customers: 'Customers',
    orders: 'Orders',
    inventory_levels: 'Inventory Levels',
    locations: 'Locations',
    inventory_items: 'Inventory Items',
    metafields: 'Metafields',
    product_key_sync: 'Product Key Sync'
};

const namespaces: any = {
    smart_collections: 'PSCM',
    custom_collections: 'PCCM',
    products: 'PPM',
    variants: 'PPM'
};

const conditionsArray: {
    operations: any,
    rules: any
} = {
    operations: function (disabledArray: string[]) { let operations: any = [
        { label: 'is equal to', value: 'equals' ,disabled: true },
        { label: 'is not equal to', value: 'not_equals',disabled: true },
        { label: 'is greater than', value: 'greater_than',disabled: true },
        { label: 'is less than', value: 'less_than',disabled: true },
        { label: 'starts with', value: 'starts_with',disabled: true },
        { label: 'ends with', value: 'ends_with',disabled: true },
        { label: 'contains', value: 'contains',disabled: true },
        { label: 'does not contains', value: 'not_contains',disabled: true },
        { label: 'Yes', value: 'is_set',disabled: true },
        { label: 'No', value: 'is_not_set',disabled: true },
    ];
    disabledArray.forEach((x: string) =>{
        let index = operations.findIndex((i:any) => i.value === x);
        operations[index].disabled = false;
    });
    return operations;
},
    rules: [
        { label: 'Product Title', value: 'product_title', operations: ['equals', 'not_equals', 'starts_with', 'ends_with', 'contains', 'not_contains'], input_type: 'text' },
        { label: 'Product Type', value: 'product_type', operations: ['equals', 'not_equals', 'starts_with', 'ends_with', 'contains', 'not_contains'], input_type: 'text' },
        { label: 'Product Vendor', value: 'vendor', operations: ['equals', 'not_equals', 'starts_with', 'ends_with', 'contains', 'not_contains'], input_type: 'text' },
        { label: 'Product Price', value: 'price', operations: ['equals', 'not_equals', 'greater_than', 'less_than'], input_type: 'currency' },
        { label: 'Product Tag', value: 'tag', operations: ['equals'], input_type: 'text' },
        { label: 'Compare At Price', value: 'compare_at_price', operations: ['equals', 'not_equals', 'greater_than', 'less_than'], input_type: 'currency' },
        { label: 'Weight', value: 'weight', operations: ['equals', 'not_equals', 'greater_than', 'less_than'], input_type: 'text' },
        { label: 'Inventory Stock', value: 'inventory', operations: ['equals', 'greater_than', 'less_than'], input_type: 'text' },
        { label: 'Variant Title', value: 'variant', operations: ['equals', 'not_equals', 'starts_with', 'ends_with', 'contains', 'not_contains'], input_type: 'text' },
    ]
}
export default {
    conditionsArray: conditionsArray,
    limit: 10,
    support_url: 'https://partners.alliance-ecommerce.in/support',
    appCode: {
        socialLoginHype: 'hypesocialloginplus',
        socialLogin: 'socialloginplus',
        timer: 'timer',
        datafeed: 'datafeed',
        metafields: 'metafields',
        autotagging: 'autotagging',
        search: 'search'
    },
    queryParams: function (location: any) {
        return new URLSearchParams(location.search);
    },
    shopify_endpoints: shopify_endpoints,
    namespaces: namespaces,
    website: 'https://alliance-ecommerce.in'
}